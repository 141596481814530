import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import decoration from '../assets/images/decoration.svg';

const InstagramWrapper = styled.div`
  position: relative;
`;

const InstagramImages = styled.div`
  display: grid;
  cursor: pointer;
  grid-template-columns: repeat(3, 1fr);

  ${({ theme }) => theme.mq.desktop} {
    grid-template-columns: repeat(6, 1fr);
  }
`;

const StyledTitle = styled.h2`
  font-weight: 600;
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.s};
  text-transform: uppercase;
  padding: 0;
  text-shadow: 1px 1px 2px lightgrey;
  font-family: 'Dancing Script', cursive;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.m};
  }
`;

const StyledDeco = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const StyledInstaName = styled.div`
  text-align: center;
  font-family: 'Dancing Script', cursive;
  padding-bottom: 2rem;

  a {
    text-decoration: none;
    font-size: ${({ theme }) => theme.font.size.s};

    ${({ theme }) => theme.mq.desktop} {
      font-size: ${({ theme }) => theme.font.size.m};
    }
  }
`;

const Instagram = () => {
  const images = useStaticQuery(graphql`{
  allFiles: allFile(filter: {relativePath: {regex: "/instagram/"}}) {
    edges {
      node {
        id
        childImageSharp {
          gatsbyImageData(width: 400, layout: CONSTRAINED)
        }
      }
    }
  }
}
`);

  return <>
    <InstagramWrapper>
      <StyledDeco src={decoration} alt="decoration" />
      <StyledTitle>Zapraszamy na nasz instagram</StyledTitle>
      <StyledInstaName>
        <a href="https://www.instagram.com/lawendowy_floristicworkshop/">
          @lawendowy_floristicworkshop
        </a>
      </StyledInstaName>
      <a href="https://www.instagram.com/lawendowy_floristicworkshop/">
        <InstagramImages>
          {images.allFiles.edges.map((node) => (
            <GatsbyImage image={node.node.childImageSharp.gatsbyImageData} key={node.node.id} />
          ))}
        </InstagramImages>
      </a>
    </InstagramWrapper>
  </>;
};

export default Instagram;
