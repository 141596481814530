import React from 'react';
import {graphql, Link} from 'gatsby';
import {GatsbyImage} from "gatsby-plugin-image";
import styled from 'styled-components';
import Offers from '../components/Offers';
import Instagram from '../components/Instagram';
import StyledButton from '../components/Button';
import SEO from '../components/SEO';

const HeroStyledWrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
`;

const StyledImage = styled(GatsbyImage)`
  position: relative;
  height: 100vh;
  width: 100%;
  filter: brightness(0.6);
`;

const StyledHeaders = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;

const StyledHeader = styled.h1`
  position: relative;
  z-index: 9997;
  text-shadow: 1px 1px 2px black;
  color: ${({theme}) => theme.white};
  font-size: ${({theme}) => theme.font.size.m};
  letter-spacing: 1rem;

  ${({theme}) => theme.mq.desktop} {
    font-size: ${({theme}) => theme.font.size.xxxl};
  }
`;

const StyledHeaderCompanyName = styled(StyledHeader)`
  font-family: 'Dancing Script', cursive;
`;

const IntroStyledWrapper = styled.div`
  margin: 50px;
  justify-content: center;
  align-content: center;
  align-items: center;
  display: grid;

  ${({theme}) => theme.mq.desktop} {
    grid-template-columns: minmax(300px, 500px) minmax(300px, 500px);
    margin: 100px;
  }
`;

const StyledTitle = styled.h2`
  font-weight: 600;
  font-size: ${({theme}) => theme.font.size.m};
  text-transform: uppercase;
  text-shadow: 1px 1px 2px lightgrey;
  font-family: 'Dancing Script', cursive;

  ${({theme}) => theme.mq.desktop} {
    font-size: ${({theme}) => theme.font.size.l};
  }
`;

const StyledIntroTitle = styled(StyledTitle)`
  ${({theme}) => theme.mq.desktop} {
    margin-right: 20px;
  }
`;

const StyledDescription = styled.div`
  font-size: ${({theme}) => theme.font.size.xs};
  text-align: center;
  line-height: 2.8rem;

  ${({theme}) => theme.mq.desktop} {
    font-size: ${({theme}) => theme.font.size.s};
    margin-right: 20px;
  }
`;

const StyledAboutImage = styled(GatsbyImage)`
  height: auto;
  width: 400px;
`;

const StyledIntroDescription = styled.div`
  text-align: center;
  font-size: ${({theme}) => theme.font.size.xs};

  ${({theme}) => theme.mq.desktop} {
    font-size: ${({theme}) => theme.font.size.s};
  }
`;

const StyledImgWrapper = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${({theme}) => theme.mq.desktop} {
    display: grid; //flex
  }
`;

const GalleryIntroStyledWrapper = styled.div`
  position: relative;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 60px;

  &:hover {
    background-color: white;

    ::before {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }

    ::after {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
`;

const GalleryIntroStyledHeaders = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  border: 2px solid white;
  z-index: 9998;
  height: 90%;
  width: 90%;
`;

const GalleryIntroStyledHeader = styled.h2`
  position: relative;
  font-weight: 600;
  text-transform: uppercase;
  z-index: 9997;
  color: ${({theme}) => theme.white};
  font-size: ${({theme}) => theme.font.size.m};
  top: 40%;
  left: 40%;
  transform: translate(-40%, -40%);
  font-family: 'Dancing Script', cursive;

  ${({theme}) => theme.mq.desktop} {
    font-size: ${({theme}) => theme.font.size.l};
  }
`;

const OffersStyledWrapper = styled.div`
  background-color: #f0e9f1;
  position: relative;
  padding: 4rem 0;
`;

const StyledTitleOffers = styled(StyledTitle)`
  padding: 1.5rem 0 0 0;
  text-align: center;
`;

const StyledGalleryImage = styled(GatsbyImage)`
  position: relative;
  height: 100%;
  width: 100%;
  opacity: 0.8;

  ${GalleryIntroStyledHeaders}:hover & {
    opacity: 1;
  }
`;

const AboutStyledButton = styled(StyledButton)`
  margin: 0 auto;
  background-color: ${({theme}) => theme.primaryFloristic};
`;

const IndexPage = ({data}) => (
    <>
        <SEO title="Strona główna"/>
        <HeroStyledWrapper>
            <StyledHeaders>
                <StyledHeaderCompanyName>Lawendowy kącik</StyledHeaderCompanyName>
                <StyledHeader>PRACOWNIA FLORYSTYCZNA</StyledHeader>
            </StyledHeaders>
            <StyledImage image={data.hero.image.asset.gatsbyImageData}/>
        </HeroStyledWrapper>
        <IntroStyledWrapper>
            <StyledIntroDescription>
                <StyledIntroTitle>{data.introAbout.title}</StyledIntroTitle>
                <StyledDescription>
                    <p>
                        Nasza pracownia oferuję profesjonalną oprawę florystyczną na różne
                        okazje. Najbliższe sercu są nam{' '}
                        <b>
                            aranżacje ślubne w postaci bukietów ślubnych, butonierek,
                            bransoletek
                        </b>
                        , a także <b>dekoracje kościoła, sali, ślubu w plenerze</b>.
                    </p>
                    <p>
                        Specjalizujemy się również we{' '}
                        <b>florystyce funeralnej i wiązankach</b> na Święto Zmarłych jak i
                        również innych dekoracjach okolicznościowych:{' '}
                        <b>Boże Narodzenie, Wielkanoc, Flower Box, Lasy w słoiku</b>.
                    </p>
                    <p>
                        Zapraszam do „naszego świata” gdzie razem z Nami przeżyjesz cudowne
                        chwile i otoczysz się pięknem kwiatów.
                    </p>
                </StyledDescription>
                <AboutStyledButton as={Link} to="/onas">
                    Dowiedź się więcej
                </AboutStyledButton>
            </StyledIntroDescription>
            <StyledImgWrapper>
                <StyledAboutImage
                    image={data.introAbout.image.asset.gatsbyImageData}
                    alt="{data.introAbout.title}"
                />
            </StyledImgWrapper>
        </IntroStyledWrapper>
        <OffersStyledWrapper>
            <StyledTitleOffers>nasza oferta</StyledTitleOffers>
            <Offers/>
        </OffersStyledWrapper>
        <GalleryIntroStyledWrapper as={Link} to="/galeria">
            <GalleryIntroStyledHeaders>
                <GalleryIntroStyledHeader>
                    {data.introGallery.title}
                </GalleryIntroStyledHeader>
            </GalleryIntroStyledHeaders>
            <StyledGalleryImage image={data.introGallery.image.asset.gatsbyImageData}/>
        </GalleryIntroStyledWrapper>
        <Instagram/>
    </>
);

export default IndexPage;

export const query = graphql`
  query HomeQuery {
    hero: sanityHome {
      title
      image {
        asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
    introAbout: sanityIntroAbout {
      description
      title
      image {
        asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
    introGallery: sanityIntroGallery {
      title
      image {
        asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
  }
`;
